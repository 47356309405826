import algoliasearch from 'algoliasearch/lite';
import { format } from 'date-fns';
import instantsearch from 'instantsearch.js';
import { searchBox, infiniteHits, refinementList, menuSelect } from 'instantsearch.js/es/widgets';
import { algoliaDatePickerWidget } from './publishingRequests/algoliaDatePickerWidget';
import { algoliaSelectizeMenuWidget } from './publishingRequests/algoliaSelectizeMenuWidget';
import { AlgoliaTapedeckRecording } from './algolia/tapedeck';

export function initPublishingRequests() {
  $('.select-recording__radio input[type=radio]').on('click', function() {
    $('.select-recording__tapedeck').hide()
    $('.select-recording__upload').hide()
    $('.select-recording__link').hide()

    $('#' + $(this).val()).show()

    clearSelection()
  })
  
  // Init external link interactivity
  $('#publishing_request_form_provide_link_later').on('change', function(e) {
      if ((e.target as HTMLInputElement).checked) {
        $('#publishing_request_form_external_link')
          .attr('disabled', 'disabled')
          .addClass('disabled')
          .val('')
      } else {
        $('#publishing_request_form_external_link')
          .removeAttr('disabled')
          .removeClass('disabled')
      }
  }) 
  
  // Initialize the Tapedeck search for recording IDs

  const input = $('#publishing_request_form_tapedeck_recording_id')
  if (!input.length) return

  const client = algoliasearch(
    input.data('algolia-app-id'),
    input.data('algolia-search-api-key')
  );

  const search = instantsearch({
    indexName: input.data('algolia-index-name'),
    searchClient: client,
    onStateChange({uiState, setUiState}) {
      clearSelection()
      setUiState(uiState)
    },
  });

  search.addWidgets([
    algoliaSelectizeMenuWidget({
      container: '#refinement-location',
      attribute: 'location',
      limit: 9999
    }),
    algoliaDatePickerWidget({
      container: '#refinement-date',
      attribute: 'date'
    }),
    infiniteHits({
      container: '#hits',
      templates: {

        item(hit, { html, components }) {
          const selected = input.val() == hit.objectID

          return html`
            <div class="hit ${selected ? 'selected' : ''}"
                data-id="${hit.objectID}"
                onClick="${() => {
                  toggleSelected(hit as unknown as AlgoliaTapedeckRecording)
                }}"
                >
              <div class="hit__timestamp">
                ${format(new Date(hit.timestamp), 'EEE MMM d, h:mm a')}
              </div>
              <div class="hit__location">
                ${components.Highlight({ attribute: 'location', hit })}
              </div>
              <div class="hit__filename">
                ${components.Highlight({ attribute: 'filename', hit })}
              </div>
              <div class="hit__content-type">
                ${components.Highlight({ attribute: 'content_type', hit })}
              </div>
              <div class="hit__duration">
                ${
                  typeof hit.metadata?.duration == 'number' ?
                    formatDuration({ seconds: hit.metadata?.duration }) :
                    hit.metadata?.duration}
              </div>
            </div>
          `
        },
      },
    })
  ]);

  // Prevent submitting the form when the loadMore button is clicked.
  input.closest('form').on('click', '.ais-InfiniteHits-loadMore', function(e: MouseEvent) {
    e.preventDefault();
  })

  search.start();

  function clearSelection() {
    input.val('')
    $('.select-recording__tapedeck').removeClass('has-selection')
    $(`.hit`).removeClass('selected')
    $('.publishing-request__preview').html(``)
    $('.select-recording__tapedeck input[type="submit"]')
      .attr('disabled', 'disabled')
      .addClass('disabled')
      .val('Choose a Recording')
    $('#tapedeck-download')
      .attr('href', '')
      .hide()
  }

  function toggleSelected(hit: AlgoliaTapedeckRecording) {
    const objectID = hit.objectID
    if (input.val() == objectID) {
      clearSelection()
    } else {
      input.val(objectID)
      $('.select-recording__tapedeck').addClass('has-selection')
      $(`.hit[data-id=${objectID}]`).addClass('selected')
      $('.publishing-request__preview').html(`
        <h6>${hit.filename}</h6>
        <audio controls>
          <source src="${hit.download_url}" type="${hit.content_type}">
        </audio>
      `)
      $('.select-recording__tapedeck input[type="submit"]')
        .removeAttr('disabled')
        .removeClass('disabled')
        .val('Use This Recording')
      $('#tapedeck-download')
        .attr('href', hit.download_url)
        .show()
    }
  }
}

const Minute = 60
const Hour = Minute * 60

function formatDuration(duration: { seconds: number }): string {
  // don't go lower than seconds
  const seconds = Math.floor(duration.seconds)

  if (seconds < Minute) {
    return `00:${leftPad(seconds)}`
  } else if (seconds < Hour) {
    return `${leftPad(Math.floor(seconds / Minute))}:${leftPad(seconds % Minute)}`
  } else {
    return `${leftPad(Math.floor(seconds / Hour))}:${leftPad(Math.floor((seconds % Hour) / Minute))}:${leftPad(seconds % Minute)}`
  }
}

function leftPad(n: number, width: number = 2, z: string = '0') {
  const nStr = n.toString()
  return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(z) + nStr
}
